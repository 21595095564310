// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Import Firebase messaging
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { app } from './firebase';  // Ensure you import the Firebase app from your configuration

// Initialize Firebase messaging
const messaging = getMessaging(app);

// Get FCM token
const requestFCMToken = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: 'YOUR_PUBLIC_VAPID_KEY' }); // Replace with your VAPID key
    if (token) {
      console.log('FCM Token:', token);
      // Send token to your server if needed
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
  }
};

// Handle foreground messages
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // Customize notification here
});

// Register service worker for FCM
serviceWorkerRegistration.register(); // Ensure the service worker is registered

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Request FCM token after service worker is registered
requestFCMToken();

reportWebVitals();
