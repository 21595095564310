import React, { useState, useEffect, Suspense, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth, database } from './firebase';
import { ref, onValue, set } from 'firebase/database';
import { UserProvider } from './UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import loadingLogo from './loading-logo.svg';
import './App.css';
import darkSound from './sounds/plunger.mp3'

const lightSound = () => {
  const audio = new Audio(darkSound);
  audio.play();
};

const CommonPage = React.lazy(() => import('./CommonPage'));
const GamingPage =  React.lazy(() => import('./GamingPage'));
const SignInPage =  React.lazy(() => import('./SignInPage'));
const SignUpPage =  React.lazy(() => import('./SignUpPage'));
const ProfilePage =  React.lazy(() => import('./ProfilePage'));
const ContactPage =  React.lazy(() => import('./ContactPage'));
const AdminPage =  React.lazy(() => import('./AdminPage'));
const ChatPage =  React.lazy(() => import('./ChatPage'));
const CommunityPage =  React.lazy(() => import('./CommunityPage'));
const CreatePostPage =  React.lazy(() => import('./CreatePostPage'));
const PostDetailPage =  React.lazy(() => import('./PostDetailPage'));
const ProfileDetailsPage =  React.lazy(() => import('./ProfileDetailsPage'));
const NotFoundPage =  React.lazy(() => import('./NotFoundPage'));
const StoryUpload =  React.lazy(() => import('./components/StoryUpload'));
const StoryDisplay =  React.lazy(() => import('./components/StoryDisplay'));
const UserProfileStories =  React.lazy(() => import('./components/UserProfileStories'));
const StoryDetail =  React.lazy(() => import('./components/StoryDetail'));
const FileStoryPage =  React.lazy(() => import('./components/FileStoryPage'));
const NoteStoryPage =  React.lazy(() => import('./components/NoteStoryPage'));
const PrivacyPolicy =  React.lazy(() => import('./PrivacyPolicy'));
const HelpPage =  React.lazy(() => import('./HelpPage'));
const NotificationPage =  React.lazy(() => import('./NotificationPage'));
const TermsAndConditions =  React.lazy(() => import('./TermsAndConditions'));
const ProfileSetupPage =  React.lazy(() => import('./ProfileSetupPage'));
const ScannerPage =  React.lazy(() => import('./components/ScannerPage'));
const InstallUpdatePage =  React.lazy(() => import('./InstallUpdatePage'));
const ThemeContext = createContext();

const App = () => {
  const [user, setUser] = useState(null);
  const [theme, setTheme] = useState('light');
  useEffect(() => {
    const handleRightClick = (e) => e.preventDefault();
    document.addEventListener('contextmenu', handleRightClick);
    return () => document.removeEventListener('contextmenu', handleRightClick);
  }, []);
  // Function to detect system theme
  const detectSystemTheme = () => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersDark ? 'dark' : 'light';
  };

  // Function to update user presence
  const updateUserPresence = (userId) => {
    const presenceRef = ref(database, `presence/${userId}`);
    set(presenceRef, { online: true, last_changed: Date.now() });
  };

  // Effect to handle user authentication and theme
  useEffect(() => {
    // Set initial theme based on system preference
    const initialTheme = detectSystemTheme();
    setTheme(initialTheme);

    const systemThemeListener = (event) => {
      if (!user) {
        setTheme(event.matches ? 'dark' : 'light');
      }
    };

    // Add event listener for system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', systemThemeListener);

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        updateUserPresence(user.uid);

        const userRef = ref(database, `users/${user.uid}/theme`);
        onValue(userRef, (snapshot) => {
          const savedTheme = snapshot.val();
          if (savedTheme) setTheme(savedTheme);
        });
      } else {
        setUser(null);
      }
    });

    return () => {
      if (user) {
        const presenceRef = ref(database, `presence/${user.uid}`);
        set(presenceRef, { online: false, last_changed: Date.now() });
      }
      unsubscribe();
      mediaQuery.removeEventListener('change', systemThemeListener);
    };
  }, [user]);

  // Toggle theme function
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    lightSound();
    setTheme(newTheme);

    if (user) {
      const userRef = ref(database, `users/${user.uid}/theme`);
      set(userRef, newTheme);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <UserProvider>
        <Router>
          <Suspense fallback={<div className="loading-icon">
            <img src={loadingLogo} alt="Loading..." />
          </div>}>
            <div className={`App ${theme}`}>
              <div className="theme-toggle">
                <button onClick={toggleTheme} aria-label="Toggle theme">
                  <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} />
                </button>
              </div>
              <Routes>
                <Route path="/" element={user ? <Navigate to="/gaming" /> : <CommonPage />} />
                <Route path="/" element={<Navigate to="/SignInPage" />} />
                <Route path="/SignInPage" element={user ? <Navigate to="/gaming" /> : <SignInPage />} />
                <Route path="/SignUpPage" element={user ? <Navigate to="/gaming" /> : <SignUpPage />} />
                <Route path="/gaming" element={<GamingPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/community" element={<CommunityPage />} />
                <Route path="/create-post" element={<CreatePostPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="post-details/:postId" element={<PostDetailPage />} />
                <Route path="/profile-details/:userId" element={<ProfileDetailsPage />} />
                <Route path="/story-upload" element={<StoryUpload />} />
                <Route path="/story-display" element={<StoryDisplay />} />
                <Route path="/UserProfileStories" element={<UserProfileStories />} />
                <Route path="/stories/:userId" element={<StoryDetail />} />
                <Route path="/upload/file" element={<FileStoryPage />} />
                <Route path="/upload/note" element={<NoteStoryPage />} />
                <Route path="/chat/:userId" element={<ChatPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/help" element={<HelpPage />} />
                <Route path="/notifications" element={<NotificationPage />} />
                <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                <Route path="/profile-setup" element={<ProfileSetupPage />} />
                <Route path="/scanner" element={<ScannerPage />} />
                <Route path="/install-update" element={<InstallUpdatePage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </Suspense>
        </Router>
      </UserProvider>
    </ThemeContext.Provider>
  );
};

export default App;
